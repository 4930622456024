<script>
export default {
  components: {
    Modal: () => import("@/components/modals/Modal.vue"),
  },
  data() {
    return {
      reason: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.user.ModelFinish;
    },
  },
  methods: {
    close() {
      this.reason = "";
      this.$store.state.user.ModelFinish = {};
    },
    sendReason(reason) {
      if (!reason) return;

      if (reason === "break") {
        this.$axios.post(`/operation/break`, {
          modelEmail: this.user.email,
        });
      } else {
        this.$axios.put(`/reviews/stateReview?id=${this.user._id}`);
        this.$axios.post(`/operation/endTransmission`, {
          modelId: this.user._id,
        });
      }
      this.$store.state.user.ModelFinish = {};
    },
  },
};
</script>

<template>
  <Modal class="modalFinish" :isDisableSubmit="!reason" @onCancel="close" @onSubmit="sendReason(reason)">
    <template v-slot:header>
      <div class="modalFinish__header">
        <h2 class="modalFinish__title">Desconexión</h2>
      </div>
    </template>
    <template v-slot:body>
      <div class="modalFinish__body">
        <p>Motivo</p>
        <button :class="['modalFinish__reason', reason === 'break' && 'modalFinish__reason--active']" @click="reason = 'break'">
          <iconic name="pause" />
          Receso
        </button>
        <button :class="['modalFinish__reason', reason === 'endTransmission' && 'modalFinish__reason--active']" @click="reason = 'endTransmission'">
          <iconic name="stop" />
          Finalizar
        </button>
      </div>
    </template>
    <template v-slot:footer></template>
  </Modal>
</template>

<style lang="scss">
.modalFinish {
  .modal-g__container {
    padding: $mpadding * 1.5 $mpadding;
    max-width: 300px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  .modal-g__buttons {
    @include Flex(row);
  }
  .modal-g__button {
    @include Flex(row);
    width: 110px;
    height: 36px;
    padding: 8px;
    font-weight: bold;
    color: $primary-color;
    background: white;
    border: 1px solid $primary-color;
    border-radius: 6px;
  }
  .modal-g__button.submit {
    color: white;
    background: $primary-color;
  }
  &__header {
    text-align: center;
  }
  &__header,
  &__body {
    width: 100%;
  }
  &__body {
    @include Flex(column);
    padding: $mpadding/2;
  }
  &__reason {
    @include Flex(row, space-around);
    width: 140px;
    height: 50px;
    padding: 10px 19px;
    margin: $mpadding/2 0;
    border: none;
    border-radius: 6px;
    background: #efefef;
    cursor: pointer;
    &--active {
      background: $primary-color;
      color: white;
      font-weight: bold;
      transition: 0.3s ease-in-out;
    }
  }
}
</style>
